import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import Pager from "../components/Pager"
import { Row}  from 'react-bootstrap'

import * as styles from '../styles/list-item.module.css'

import ArticlePreview from '../components/ArticlePreview'

const Joomla_List = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <Seo
                title="Computer Tips and Tricks"
                description="Computer Tips and Tricks"
                keywords="Tips and Tricks, Tips, Tricks, computer tips and tricks"
            />     
            <h1>Computer Tips and Tricks</h1>           
                 
            <Row className={styles.list}>
                {examples.map(item => (
               <ArticlePreview key={item.id}
                   title={item.frontmatter.title}
                   link = {"/" + item.frontmatter.slug}
                   published= {item.frontmatter.published}
                   image = "/images/icons/tips.png"

                />           
            ))}
            </Row>
            <Pager pageContext={pageContext} />
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/examples/tips/*"}}
        sort: {fields: frontmatter___order, order: DESC}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default Joomla_List;